<!DOCTYPE html>
<div class="container">
    <el-row>
        <el-col :span="15" class="topic-panel">
            <el-carousel height="100%" arrow="never">
                <el-carousel-item v-for="item in carousel_items" :key="item">
                    <div class="carousel_box">
                        <div class="carousel_box_text">
                            <p class="text_1">{{item.title}}</p>
                            <p class="text_2">{{item.description}}</p>
                            <p class="text_3">{{item.english}}</p>
                        </div>
                        <el-image :src="item.image" fit="fill" style="width: 100%;"></el-image>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </el-col>
        <el-col :span="9" class="login-form">
            <div style="max-width: 380px;width: 100%;">
                <div class="tabs">
                    <div class="tabs-head">
                        <div :class="activeName === 'first' ? 'tabs-name active' : 'tabs-name'"
                            @click="switchView('first')">登录</div>
                        <div :class="activeName != 'first' ? 'tabs-name active' : 'tabs-name'"
                            @click="switchView('second')">注册</div>
                    </div>
                    <div class="tabs-body" v-if="activeName==='first'">
                        <template v-if="!registered_step_2nd">
                            <el-row>
                                <x-form ref="form-login" 
                                    style="width: 100%;" 
                                    label-layout="placeholder" 
                                    row-gap="8px"

                                    asterisk-hidden

                                    enable-verification

                                    :metadata="login_metadata" 
                                    :data-binding="loginBinding" 
                                    writeonly 
                                    submit-on-enter
                                    @enter-submit="handleLoginClick">
                                </x-form>
                            </el-row>

                            <el-row class="field-row"
                                :style="'justify-content: end;font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN;font-weight: 400;color: #2E64F3;'">
                                <a style="cursor:pointer;">忘记密码？</a>
                            </el-row>

                            <el-row style="padding: 0px;">
                                <el-col :span="24">
                                    <el-button style="width:100%" type="primary"
                                        @click="handleLoginClick">登&nbsp&nbsp&nbsp&nbsp录</el-button>
                                </el-col>
                            </el-row>
                        </template>
                        
                    </div>
                    <div class="tabs-body" v-if="activeName==='second'">
                        <template v-if="!registered_step_2nd">
                            <el-row>
                                <!-- 用户注册表单 -->
                                <x-form 
                                    ref="form-reg" 
                                    style="width: 100%;" 
                                    label-layout="placeholder" 
                                    row-gap="8px"

                                    asterisk-hidden

                                    group-label-hidden 
                                    writeonly 
                                    submit-on-enter 

                                    :enable-verification="registerStep == 1"

                                    v-model="userRegisterInfo"
                                    v-model:valid="userRegFormValid"

                                    :metadata="userRegMetadata"
                                    :data-binding="userRegBinding" 
                                    :data-interface="userRegisterInterface"

                                    @dataChanged="handleUserRegisterationChanged" >
                                </x-form>
                            </el-row>
                            <template v-if="registerStep == 0">
                                <el-row style="padding: 0px;">
                                    <el-col :span="24">
                                        <el-button 
                                            style="width:100%" 
                                            type="primary"
                                            
                                            @click="gotoNextStepRegistry">下&nbsp;一&nbsp;步</el-button>
                                    </el-col>
                                </el-row>
                            </template>
                            <template v-else>
                                <el-row style="align-items: center;">
                                    <el-checkbox v-model="agreementChecked" @change="checkBoxChange"><br /></el-checkbox>
                                    <div class="checked-text">同意软件用户
                                        <a style="cursor:pointer;color: #2E64F3;" href="template-service.html"
                                        target="_blank">《服务协议》</a>
                                        和
                                        <a style="cursor:pointer;color: #2E64F3;" href="template-privacy.html"
                                        target="_blank">《隐私政策》</a>
                                    </div>
                                </el-row>
                                <el-row style="padding: 0px;">
                                    <el-col :span="24">
                                        <el-button class="primary-button" style="width:100%" type="primary" :disabled="!userRegFormValid"
                                        @click="handleRegister">完成注册</el-button>
                                    </el-col>
                                </el-row>
                                <el-row style="padding: 0px;">
                                    <el-col :span="6">
                                        <!-- <x-button
                                            style=" height: 2rem; font-size:medium; color:black; background-color:white;"
                                            @click="resetRegistration">
                                            重&nbsp;&nbsp;置
                                        </x-button> -->
                                    </el-col>
                                    <el-col :span="12"></el-col>
                                    <el-col :span="6">
                                        <x-button
                                            class="previous-step-button"
                                            pattern="icon-text" icon="bs-svg-arrow-left" text="上一步"
                                            @click="gotoPreviousStepRegistry">
                                        </x-button>
                                    </el-col>
                                </el-row>
                            </template>
                        </template>
                       
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
    <el-dialog v-model="registerSuccess" title="" :show-close="false" style="width:50rem; height: 24rem;">
        <div class="dialog-container">
            <div class="image">
                <el-image :src="image" fit="fill" style="width: 80px;"></el-image>
            </div>
            <div class="description">
                恭喜您注册成功！
            </div>
            <div class="button">
                <el-button type="primary" @click="closeRegisterSuccessDialog">立即登录</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="agreementVisible" title="" :show-close="false" width="600px" :fullscreen="false" align-center
        style="border-radius: 25px;">
        <div class="agreement">
            <p class="title">服务协议和隐私条款</p>
            <p class="content">欢迎您使用链聚信息星河低代码平台，根据个人信息安全规范的要求，尊重并保护用户的个人隐私安全，请您在使用前仔细阅读
                <a style="color: #2E64F3;" href="template-service.html" target="_blank">《服务协议》</a>与<a
                    style="color: #2E64F3;" href="template-privacy.html" target="_blank">《隐私条款》</a>，同意后开启我们的服务
            </p>
            <div class="action">
                <!-- <div class="button" @click="agree(false)">不同意</div>
                <div class="button" @click="agree(true)">同意</div> -->
                <el-button  type="info" @click="agree(false)">不&nbsp;同&nbsp;意</el-button>
                <el-button style="background: #5e72e4;" type="primary" @click="agree(true)">同&nbsp;意</el-button>
            </div>
        </div>
    </el-dialog>
        
    <x-dialog  
        ref="dlg-telnum-verify" 

        title="真实性验证"
        width="33rem"
        height="22rem"
        >
        <div class="telnum-verify-panel">
            
            <div class="inputs">
                <template v-if="verificationToken == undefined">
                    <div class="tips">
                        <x-icon icon="bs-svg-info-circle-fill" size="1.8rem"></x-icon> 验证您的手机号码<span class="telnum">{{userRegisterInfo.mobileNumber}}</span> 
                    </div>
                    <!-- 发送验证码 -->
                    <x-form ref="form-send-verification" 
                        label-layout="placeholder" 
                        row-gap="8px"
            
                        :metadata="telnumVerfRequestMetadata" 
                        :data-binding="telnumVerfRequestBinding" 

                        v-model="telnumVerfRequestData"
                        v-model:valid="sendingCodeValid"
                        :disabled="sendingWaitCount >  0"

                        enable-verification
                        asterisk-hidden

                        writeonly >
                    </x-form>
                    <x-button 
                        class="verify sendCode" 
                        pattern="primary" 
                        :disabled="!sendingCodeValid || sendingWaitCount >  0" 
                        :text="'发送短信验证码' + (sendingWaitCount ? '('+sendingWaitCount+')' : '')" 
                        @click="sendCode">
                    </x-button>
                </template>
                <!-- 尚未获得手机验证凭证 -->
                <template v-else-if="!passedTelnumCredential">
                    <div class="tips send-success">
                        <x-icon icon="bs-svg-info-circle-fill" size="1.8rem"></x-icon> <span>短信验证码发送成功！</span> 
                    </div>
                    <!-- 输入验证码 -->
                    <x-form ref="form-telnum-verf" 
                        label-layout="placeholder" 
                        row-gap="8px"

                        asterisk-hidden
            
                        :metadata="telnumVerificationMetadata" 
                        :data-binding="telnumVerificationBinding" 
                        v-model="telnumVerification"
                        v-model:valid="verifyTelnumValid"
                        
                        :disabled="sendingTelnumCredential"
    
                        writeonly 
                        submit-on-enter
                        @enter-submit="verifyTelnum">
                    </x-form>
                    <x-button 
                        class="verify verifyTelnum" 
                        pattern="primary" 
                        :disabled="!verifyTelnumValid" 
                        text="验证手机号码" 
                        @click="verifyTelnumber">
                    </x-button>
                </template>
                <!-- 已验证通过 -->
                <template v-else>
                    <div class="tips send-success">
                        <x-icon icon="bs-svg-info-circle-fill" size="1.8rem"></x-icon> <h2>验证通过！</h2> 
                    </div>

                    <x-button 
                        class="verify" 
                        pattern="primary" 
                        :text="'继续注册' + (telnumVerificationFinishCounter ? '('+telnumVerificationFinishCounter+')' : '')" 
                        @click="finishVerifyAndNextToRegistry">
                    </x-button>
                </template>
                
            </div>
        </div>
    </x-dialog>
</div>