import { ElMessage } from "element-plus";
import login_1_bg from "../../../assets/login_carousel_background_1_1.png";
import login_2_bg from "../../../assets/login_carousel_background_2_1.png";
import success_image from "../../../assets/success.png";

import { Logger } from "flexue";
const LOGGER = Logger.get("com.linkgie.galaxylowcode.login");

import LOGIN_APP_METADATA from "../login.metadata.js";

// 数据模型；
const USER_MODEL = {
  name: "userpassword",
  fields: [
    { key: "username", name: "用户名", type: "Text", required:true, defaultValue: "" },
    { key: "password", name: "密码", type: "Text", required: true, contentType: "password", defaultValue: "" },
  ],
};

// 数据视图元数据；
//登录信息
const USER_METADATA = {
  name: "userpassword",
  title: "用户登录信息",
  fields: [
    { key: "username", name: "请输入登录账号", dataKey: "username" },
    { key: "password", name: "请输入登录密码", dataKey: "password" },
  ],
};

// 手机号码验证请求模型；
const TELNUM_VERF_REQ_MODEL = {
  name: "TelnumVerificationRequest",
  fields: [
    { key: "phoneNumber", name: "手机号码", type: "Text", required: true, defaultValue: "", contentType: "plain;maxLength=6"},
  ],
};
//手机号码验证请求元数据；
const TELNUM_VERF_REQ_METADATA = {
  name: "TelnumVerificationRequest",
  title: "手机验证码输入",
  fields: [
    { key: "phoneNumber", name: "短信验证码", dataKey: "phoneNumber", readonly:true },
  ],
};

// 手机号码验证模型；
const TELNUM_VERF_MODEL = {
  name: "TelnumVerification",
  fields: [
    { key: "verificationCode", name: "短信验证码", type: "Text", required: true, defaultValue: "", contentType: "plain;maxLength=6;minLength=6"},
  ],
};

// 数据视图元数据；
//手机号码验证
const TELNUM_VERF_METADATA = {
  name: "TelnumVerification",
  title: "手机验证码输入",
  fields: [
    { key: "verificationCode", name: "输入短信验证码", dataKey: "verificationCode" },
  ],
};

const USER_REG_MODEL = LOGIN_APP_METADATA.dataStructure.models["com.linkgie.galaxyframework.service.account.UserRegisterationVO"];

const UER_REG_BASE_INTERFACE = "com.linkgie.galaxyframework.service.account.UserRegisterationVI";

const UER_REG_ENT_DEV_BG_INTERFACE = "com.linkgie.galaxyframework.service.account.EnterpriseDeveloperBackgroundInfoVI";

const UER_REG_DEV_BG_INTERFACE = "com.linkgie.galaxyframework.service.account.DeveloperBackgroundInfoVI";

console.log("~~~~~~~~~~~~ USER REG MODEL ~~~~~~~~~~~", USER_REG_MODEL);

const USER_REG_BINDING = {
  // 模型；
  model: USER_REG_MODEL,
  // 数据；
  data: {
    // 开发者类型: 个人开发者(INDIVIDUAL), 企业开发者(ENTERPRISE)；默认值：INDIVIDUAL;
    developerType: "INDIVIDUAL",
  },
};

/**
 * 登录页面组件；
 */
export default {
  name: "login-page",

  data() {
    const USER_VIEW = {
      model: USER_MODEL,
      data: {
        username: "",
        password: "",
      },
    };
    const TELNUM_VERF_VIEW = {
      model: TELNUM_VERF_MODEL,
      data: {
        verificationCode: "",
      },
    };
    const TELNUM_VERF_REQ_VIEW = {
      model: TELNUM_VERF_REQ_MODEL,
      data: {
        phoneNumber: undefined,
      },
    };

    // 如果页面的链接包含了锚点 reg ，则自动切换到注册界面；
    const urlParams = new URL(document.location.href);
    const defaultActiveName = ("#/reg" == urlParams.hash) ? "second" : "first";

    console.log("-------- page.url.hash = ", urlParams.hash, "  -------- defaultActiveName = ", defaultActiveName);

    return {
      login_metadata: USER_METADATA,
      loginBinding: USER_VIEW,

      userRegMetadata: {},
      userRegBinding: USER_REG_BINDING,

      // 用户注册表单上单向绑定的注册表单编辑对象；
      // 通过此对象可以获得当前正在输入中的所有用户注册信息；
      userRegisterInfo: undefined,

      telnumVerificationMetadata: TELNUM_VERF_METADATA,
      telnumVerificationBinding:TELNUM_VERF_VIEW,

      telnumVerfRequestMetadata: TELNUM_VERF_REQ_METADATA,
      telnumVerfRequestBinding:TELNUM_VERF_REQ_VIEW,

      regIndividualDeveloper: false,

      registerStep: 0,

      jumpTo: "1",

      rememberMe: false,

      // 用户注册表单的输入验证状态；
      userRegFormValid: false,

      //
      carousel_items: [
        {
          title: "星河低代码开发平台",
          description: "加速企业应用开发",
          english: "Galaxy LowCode Development Platform",
          image: login_1_bg,
        },
        {
          title: "星河低代码开发平台",
          description: "让团队快速构建更好的应用",
          english: "Galaxy LowCode Development Platform",
          image: login_2_bg,
        },
      ],
      activeName: defaultActiveName,
      agreementChecked: false,
      registered_step_2nd: false,

      registerSuccess: false,
      agreementVisible: false,
      image: success_image,

      // 手机号验证对话框相关状态；
      // 短信验证码发送表单的数据状态；
      telnumVerfRequestData: undefined,

      // 手机验证接收验证表单的数据状态；
      telnumVerification: undefined,

      sendingCodeValid: false,
      sendingWaitCount: undefined,
      
      verifyTelnumValid: false,

      verificationToken: undefined,

      sendingTelnumCredential: false,

      // 已验证通过的手机号码；
      passedTelnum: undefined,
      // 手机号码验证通过获得的凭据，与 passedTelnum 的号码对应；
      passedTelnumCredential: undefined,

      telnumVerificationFinishCounter: undefined
    };
  },

  computed: {
    userRegisterInterface() {
      if (this.registerStep == 0) {
        return UER_REG_BASE_INTERFACE;
      }
      if (this.regIndividualDeveloper) {
        return UER_REG_DEV_BG_INTERFACE;
      }
      return UER_REG_ENT_DEV_BG_INTERFACE;

      // return [UER_REG_BASE_INTERFACE];
    },
  },

  inject: ["serviceContext"],

  created() {
    let authService = this.serviceContext.createService({ name: "authService", deepDecoding: true });

    // 登录方法；
    authService.createAction({
      name: "login",
      path: "/login",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "No-Redirect": "true",
      },

      formParams: {
        username: { required: true },
        password: { required: true },
      },
    });

    // 获取注册模型的字段转为参数表；
    const REG_MODEL_PARAMS = {};
    USER_REG_MODEL.fields.forEach((f) => {
      REG_MODEL_PARAMS[f.key] = { required: f.required };
    });

    // 注册方法；
    authService.createAction({
      name: "register",
      path: "/api/register/user/secure",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      bodyParams: {
        required: true,
        model: REG_MODEL_PARAMS,
      },
    });

    // 发送短信验证码；
    authService.createAction({
      name: "sendRegisterSms",
      path: "/api/security/verification/sms/user-register/token",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      formParams: {
        phoneNumber: { required: true },
      },
    });


    // 发送短信验证码；
    authService.createAction({
      name: "verifyPhoneNumber",
      path: "/api/security/verification/sms/user-register/credential",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      formParams: {
        token: { required: true },
        verificationCode: { required: true },
      },
    });


  },

  mounted() {},

  methods: {
    handleRegister() {
      const THIS = this;
      // 验证表单输入；
      const form = this.$refs["form-reg"];
      form.validate().then(()=>{
        if (THIS._checkAgreement()) {
          // 提交注册表单；
          THIS._doRegistration();
        }else{
          THIS.$notifyError("未同意服务和隐私协议！", "未同意协议");
        }
      })
      .catch((error)=>{
        // 校验失败，不需做任何处理；
        LOGGER.warn("The registration form validation failed!  -- ", error);
      });
    },

    _checkAgreement(){
      if (!this.agreementChecked) {
        this.agreementVisible = true;
        return false;
      }
      return true;
    },

    /**
     * 显示手机号码验证对话框；
     */
    _showPhoneNumberVerifyDialog(){
      // 验证手机号码；
      // 此步骤安排在勾选了同意协议，以及真人验证码输入验证通过之后；
      const dialog = this.$refs["dlg-telnum-verify"];
      dialog.open();
    },

    /**
     * 显示手机号码验证对话框；
     */
    _closePhoneNumberVerifyDialog(){
      // 验证手机号码；
      // 此步骤安排在勾选了同意协议，以及真人验证码输入验证通过之后；
      const dialog = this.$refs["dlg-telnum-verify"];
      dialog.close();
    },

    /**
     * 提交注册； 
     */
    _doRegistration(){
      const registerAction = this.serviceContext.services.authService.actions.register;
      const THIS = this;
      this.$refs["form-reg"].submitToService(registerAction, {
        responseHandler: function (response, requestData) {
          ElMessage({
            showClose: true,
            message: "注册成功！",
            type: "success",
          });

          // 转到显示注册成功对话框；
          THIS.showRegisterSuccessDialog();
          
          // 清理注册信息；
          THIS.clearRegistration();
          // 重定向到登录页；
          THIS.redirectToLogin();

          return requestData;
        },

        errorHandler: function (error) {
          const message = error.message || error.statusText || error;
          THIS.$promptError("注册失败！" + message, "注册失败");
        },
      });
    },

    /**
     * 验证注册用户的基本信息；
     * 
     * 验证通过后，如果手机号码未验证，则先打开验证对话框进行验证；
     */
    gotoNextStepRegistry() {
      
      const THIS = this;
      const form = this.$refs["form-reg"];

      form.validate().then((v)=>{
        if (v.valid) {
          // 如果当前输入的手机号码尚未验证，则先打开验证对话框进行验证；
          // 如果已经验证，则直接到下一步；
          const registerInfo = THIS.userRegisterInfo;
          if (registerInfo.mobileVerifyCredential == undefined || registerInfo.mobileNumber != THIS.passedTelnum) {
            // 先校验手机号码；

            // 清空可能已经存在的上一次验证状态；
            THIS.resetRegitsterStates()
            registerInfo.mobileVerifyCredential = undefined;
            // 打开验证对话框；
            THIS._showPhoneNumberVerifyDialog();
          }else{
            // 已经验证，则直接到下一步；
            THIS._showNextStepRegistry();
          }
        }
      })
      .catch((error)=>{
        // 校验失败，不需做任何处理；
        LOGGER.error("The registration form validation failed!  -- ", error);
      });
    },


    /**
     * 退回上一步的注册界面；
     */
    gotoPreviousStepRegistry() {
      if (this.registerStep > 0) {
        // 退回上一步注册；
        this.registerStep = this.registerStep - 1;
        // 清理表单验证信息；
        const form = this.$refs["form-reg"];
        form.clearValidate();
      }
    },

    /**
     * 显示注册的第二步；
     */
    _showNextStepRegistry(){
      if (this.registerStep < 1) {
        this.registerStep = 1;

        const form = this.$refs["form-reg"];
        if (form) {
          form.focusToField("teamRole");
        }
      }
    },

    /**
     * 重置注册信息；
     */
    resetRegistration(){
      const THIS = this;
      this.$confirm("请问要重置注册信息吗？", "重置").then(()=>{          
        // 清理注册信息；
        THIS.clearRegistration();
        // 重定向到登录页；
        THIS.redirectToLogin();

      })
    },

    clearRegistration(){
      this.loginBinding.data = {};
      this.userRegBinding.data = {};

      this.agreementChecked = false;

      const form = this.$refs["form-reg"];
      form.reset;

      this.resetRegitsterStates()
    },



    resetRegitsterStates(){
      // 清空可能已经存在的上一次验证状态；
      this.telnumVerificationBinding.data = {};
      this.telnumVerfRequestBinding.data = {};

      // -- 验证请求相关的状态；
      this.sendingCodeValid = false,
      this.sendingWaitCount = undefined,
      this.verifyTelnumValid = false,
      this.verificationToken = undefined,
      this.sendingTelnumCredential = false,
      // -- 验证结果相关的状态；
      this.passedTelnum = undefined;
      this.passedTelnumCredential = undefined;

  },

    handleUserRegisterationChanged(event) {
      if (event.data.developerType == "INDIVIDUAL") {
        this.regIndividualDeveloper = true;
      } else {
        this.regIndividualDeveloper = false;
      }
    },

    handleLoginClick() {
      // const loginAction = this.serviceContext.services.authService.http.POST["/login"];
      const loginAction = this.serviceContext.services.authService.actions.login;
      const jumpTo = this.$data.jumpTo;
      this.$refs["form-login"].submitToService(loginAction, {
        responseHandler: function (response, requestData) {
          ElMessage({
            showClose: true,
            message: "登录成功！",
            type: "success",
          });

          let redirectURL = undefined;
          if (response.code == 10302) {
            //跳转；
            redirectURL = response.data;
          }
          if (redirectURL && redirectURL != "/") {
            window.location.replace(redirectURL);
          } else {
            if (jumpTo == "2") {
              // 跳转到控制台；
              window.location.replace("/console.html");
            } else {
              // 默认跳转到应用首页；
              window.location.replace("/");
            }
          }

          return requestData;
        },

        errorHandler: function (errorResponse) {
          console.error("登录失败！-- error response=", errorResponse);
          ElMessage({
            showClose: true,
            message: "登录失败！ " + errorResponse.message,
            type: "error",
          });
        },
      });
    },

    /**
     * 切换登录界面和注册界面；
     */
    switchView(tab) {
      if (this.activeName == "second" && tab == "first" && this.passedTelnumCredential != undefined) {
        this.$promptWarning("当前注册尚未完成！", "注册未完成");
      }else{
        this.activeName = tab;
      }
    },

    showRegisterSuccessDialog() {
      this.registerSuccess = true;
    },

    closeRegisterSuccessDialog(){
      this.registerSuccess = false;
      this.redirectToLogin();

      this.resetRegitsterStates();
    },

    redirectToLogin() {
      
      this.registerStep = 0
      this.registered_step_2nd = false;
      this.activeName = "first";
    },
    
    checkBoxChange() {
      if (this.agreementChecked) {
        this.agreementVisible = true;
      }
    },
    agree(val) {
      this.agreementChecked = val == true;
      this.agreementVisible = false;
    },

    /**
     * 发送验证码；
     */
    sendCode(){
      const THIS = this;
            
      // 开始倒计时等待；
      this.sendingWaitCount = 30;
      const intervalId = setInterval(() => {
        THIS.sendingWaitCount = THIS.sendingWaitCount - 1;
        if (THIS.sendingWaitCount <= 0) {
          clearInterval(intervalId);
        }
      }, 1000);

      // 设置发送验证码的手机号码；
      this.telnumVerfRequestData.phoneNumber = this.userRegisterInfo.mobileNumber;
      // 请求验证码；
      const sendRegisterSmsAction = this.serviceContext.services.authService.actions.sendRegisterSms;
      const form = this.$refs["form-send-verification"];
      form.submitToService(sendRegisterSmsAction, {
        responseHandler: function (response) {
          // 发送成功，取得验证 TOKEN ；
          THIS.verificationToken = response.data;

          clearInterval(intervalId);
        },
        errorHandler: function (error) {
          // 发送失败；
          const message = error.message || error.statusText || error;
          THIS.$promptError("短信验证码发送失败! " + message , "发送失败");

          // 重置发送状态；服务端控制了重复发送的次数；
          clearInterval(intervalId);
          THIS.sendingWaitCount = undefined;
        }
      });
    },

    verifyTelnumber(){
      // 验证手机号码；
      this.sendingTelnumCredential = true;

      const THIS = this;

      const verifyPhoneNumberAction = this.serviceContext.services.authService.actions.verifyPhoneNumber;
      verifyPhoneNumberAction.request({
        params: {
          token: this.verificationToken,
          verificationCode: this.telnumVerification.verificationCode,
      }}).then((response)=>{
        // 发送成功，取得手机验证凭据；
        const telnumCredential = response.data;

        // 记录返回的手机号验证凭证ID到用户注册表单的编辑对象上，以便提交表单时携带提交；
        THIS.passedTelnumCredential = telnumCredential;
        THIS.userRegisterInfo.mobileVerifyCredential = telnumCredential;
        THIS.passedTelnum = THIS.userRegisterInfo.mobileNumber;

        // 设置 5 秒倒计时，自动关闭验证对话框，继续下一步注册；
        THIS.countdownAndContinueRegister();

      }).catch((error)=>{
        // 发送失败；
        THIS.sendingTelnumCredential = false;
        const message = error.message || error.statusText || error;
        THIS.$promptError("手机号码验证失败! " + message , "验证失败");
      });
    },

    /**
     * 设置 5 秒倒计时，自动关闭验证对话框，继续下一步注册；
     */
    countdownAndContinueRegister(){
      // 设置 5 秒倒计时，自动关闭验证对话框，继续下一步注册；
      this.telnumVerificationFinishCounter = 5;
      const intervalId = setInterval(() => {
        this.telnumVerificationFinishCounter = this.telnumVerificationFinishCounter - 1;
        if (this.telnumVerificationFinishCounter <= 0) {
          clearInterval(intervalId);

          // 关闭手机验证框，继续下一步注册；
          this.finishVerifyAndNextToRegistry();
        }
      }, 1000);
    },

    /**
     *  关闭手机验证框，继续下一步注册；
     */
    finishVerifyAndNextToRegistry(){

        // 关闭手机验证框；
        this._closePhoneNumberVerifyDialog();

        // 显示下一步注册信息；
        this._showNextStepRegistry();
        
    },

    cancelVerifyTelnumber(){
      const dialog = this.$refs["dlg-telnum-verify"];
      if (!dialog) {
        return;
      }
      dialog.close();
    }

  },
};
