export default {
  metadataVersion: "v1",
  applicationId: "galaxy-lowcode-platform-login",
  applicationName: "星河低代码 | 登录",
  profileNames: ["login"],
  profiles: {
    login: {
      profile: "login",
      title: "登录",
      page: "/login.html",
      order: 100,
      features: {
        menus: [],
        toolbar: [],
      },
    },
  },
  dataStructure: {
    models: {
      "com.linkgie.galaxyframework.service.account.UserRegisterationVO": {
        "name": "com.linkgie.galaxyframework.service.account.UserRegisterationVO",
        "topic": "UserRegisterationVO",
        "fields": [{
          "key": "name",
          "name": "姓名/昵称",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 0,
          "type": "TEXT",
          "iterable": false,
          "required": true,
          "contentType": "plain;maxLength=20"
        },{
          "key": "username",
          "name": "登录用户名",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 1,
          "type": "TEXT",
          "iterable": false,
          "required": true,
          "contentType": "plain;maxLength=20;minLength=6"
        }, {
          "key": "password",
          "name": "登录密码",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 2,
          "type": "TEXT",
          "iterable": false,
          "required": true,
          "contentType": "password;doubleCheck=true;maxLength=20;minLength=6"
        }, {
          "key": "mobileNumber",
          "name": "手机号码",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 4,
          "type": "TEXT",
          "iterable": false,
          "required": true,
          "contentType": "telnum;leadingNum=[\"13\",\"145\",\"147\",\"149\",\"15\",\"162\",\"165\",\"166\",\"167\",\"17\",\"18\",\"19\"];length=11"
        }, {
          "key": "mobileVerifyCredential",
          "name": "手机验证码",
          "readonly": true,
          "writeonly": false,
          "modifiable": true,
          "order": 4,
          "type": "TEXT",
          "iterable": false,
          "required": true,
        }, {
          "key": "email",
          "name": "电子邮箱",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 6,
          "type": "TEXT",
          "iterable": false,
          "required": true,
          "contentType": "email;maxLength=40"
        }, {
          "key": "developerType",
          "name": "开发者类型",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 10,
          "type": "TEXT",
          "iterable": false,
          "required": true,
          "contentType": "enum;enum.INDIVIDUAL=\"个人开发者\";enum.ENTERPRISE=\"企业开发者\""
        }, {
          "key": "teamRole",
          "name": "担任团队角色",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 11,
          "type": "TEXT",
          "iterable": false,
          "required": true,
          "contentType": "enum;enum.ARCHITECT=\"架构师\";enum.FULL_STACK=\"独立全栈\";enum.PROGRAMMER_BG=\"后端开发\";enum.PROGRAMMER_UI=\"前端开发\";enum.PRODUCT_MANAGER=\"产品经理\";enum.PROJECT_MANAGER=\"项目经理\";enum.CTO_CIO=\"首席技术官（CTO/CIO）\";enum.BOSS=\"老板\";enum.OTHER=\"其它\""
        }, {
          "key": "city",
          "name": "所在城市",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 12,
          "type": "TEXT",
          "iterable": false,
          "required": false
        }, {
          "key": "companyIndustryType",
          "name": "所属行业",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 21,
          "type": "TEXT",
          "iterable": false,
          "required": false,
          "contentType": "enum;enum.SOFTWARE_OUTSOURCING=\"软件外包\";enum.ENTERPRISE_SOFTWARE=\"企业管理软件\";enum.SYSTEM_INTEGRATION_SOLUTION=\"系统集成与解决方案\";enum.E_COMMERCE=\"电子商务\";enum.MANUFACTURING=\"制造行业\";enum.CHEMICAL=\"化工行业\";enum.RETAIL=\"零售行业\";enum.FOREIGN_TRADE=\"外贸行业\";enum.EDUCATION=\"教育行业\";enum.CONSTRUCTION=\"建筑行业\";enum.LOGISTICS=\"物流行业\";enum.AGRICULTURE=\"农业\";enum.OTHER=\"其它\""
        }, {
          "key": "company",
          "name": "任职机构",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 22,
          "type": "TEXT",
          "iterable": false,
          "required": false,
          "contentType": "plain;maxLength=30"
        }, {
          "key": "employeeSizeRange",
          "name": "机构人员规模",
          "readonly": false,
          "writeonly": false,
          "modifiable": true,
          "order": 23,
          "type": "TEXT",
          "iterable": false,
          "required": false,
          "contentType": "enum;enum.TINY=\"小型（20人以下）\";enum.SMALL=\"中小型（20~50人）\";enum.MEDIUM=\"中型（50~200人）\";enum.LARGE=\"大型（200~500人）\";enum.HUGE=\"超大型（500人以上）\""
        }],
        "interfaces": ["com.linkgie.galaxyframework.service.account.UserRegisterationVI", "com.linkgie.galaxyframework.service.account.EnterpriseDeveloperBackgroundInfoVI", "com.linkgie.galaxyframework.service.account.DeveloperBackgroundInfoVI"]
      },
      //-------
    },
    interfaces: {

      "com.linkgie.galaxyframework.service.account.DeveloperBackgroundInfoVI": {
        "name": "com.linkgie.galaxyframework.service.account.DeveloperBackgroundInfoVI",
        "topic": "DeveloperBackgroundInfoVI",
        "fields": [{
          "key": "developerType",
          "name": "开发者类型",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 10
        }, {
          "key": "teamRole",
          "name": "担任团队角色",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 11
        }, {
          "key": "city",
          "name": "所在城市",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 12
        }]
      },
      "com.linkgie.galaxyframework.service.account.EnterpriseDeveloperBackgroundInfoVI": {
        "name": "com.linkgie.galaxyframework.service.account.EnterpriseDeveloperBackgroundInfoVI",
        "topic": "EnterpriseDeveloperBackgroundInfoVI",
        "fields": [{
          "key": "developerType",
          "name": "开发者类型",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 10
        }, {
          "key": "teamRole",
          "name": "担任团队角色",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 11
        }, {
          "key": "city",
          "name": "所在城市",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 12
        }, {
          "key": "company",
          "name": "任职机构",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 21
        }, {
          "key": "companyIndustryType",
          "name": "所属行业",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 22
        }, {
          "key": "employeeSizeRange",
          "name": "机构人员规模",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 23
        }]
      },
      "com.linkgie.galaxyframework.service.account.UserRegisterationVI": {
        "name": "com.linkgie.galaxyframework.service.account.UserRegisterationVI",
        "topic": "UserRegisterationVI",
        "fields": [{
          "key": "name",
          "name": "姓名 / 昵称",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 0
        },{
          "key": "username",
          "name": "登录用户名",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 1
        }, {
          "key": "password",
          "name": "登录密码",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 2
        }, {
          "key": "mobileNumber",
          "name": "手机号码",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 4
        },{
          "key": "mobileVerifyCredential",
          "name": "手机验证码",
          "readonly": true,
          "writeonly": false,
          "modifiable": false,
          "order": 4,
        }, {
          "key": "email",
          "name": "电子邮箱",
          "readonly": false,
          "writeonly": false,
          "modifiable": false,
          "order": 6
        }]
      },

    },
  },
};
