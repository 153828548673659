import { runApp, Logger } from "flexue";
import { BootstrapIcons } from "flexue-icons";
import loginMetadata from "./login.metadata.js";

Logger.level = "INFO";

import LOGO from "../../assets/logo.png";

import Login from "./login/login.vue";


runApp(
  "#page",
  {
    title: "登录低代码平台",
    logo: LOGO,
    entry: Login,

    libs:[ BootstrapIcons],

    enableGalaxyService:false,
    galaxyAppMetadata: loginMetadata,
  }, 
  
  // // 回调函数;初始化；
  // // (context, vueApp)
  // () => {
  //   // 退出等待画面；
  //   setTimeout(()=>{
  //     document.body.classList.add('loaded');
  //   }, 400);
  // }
);
